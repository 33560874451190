import React from 'react';

import './ImageWithCredit.css';

const imagewithCredit = (props) => (
  <div className="Container">
    <img src={props.src} alt={props.alt}/>
  <div className="Credit">Photo by <a href={props.link} target="_blank" rel="noreferrer">{props.name}</a></div>
  </div>
);

export default imagewithCredit;