import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import InstagramLogo from '../../assets/images/contact/instagram-logo-square.png';
import YoutubeLogo from '../../assets/images/contact/youtube-logo-square.png';
import LinkedinLogo from '../../assets/images/contact/linkedin-logo-square.png';
import './Contact.css';

class Contact extends Component {
  state = {
    formFirstName: '',
    formLastName: '',
    formEmail: '',
    formSubject: '',
    formMessage: '',
    showModal: false,
    modalMessage: "Thank you for reaching out. I will reply as soon as I can!"
  }

  onInputChangeHandler = (event, controlId) => {
    const updatedState = {
      ...this.state,
      [controlId]: event.target.value
    }
    this.setState(updatedState);
  }

  onFormSubmit = (event) => {
    event.preventDefault();
    const { formFirstName, formLastName, formEmail, formSubject, formMessage } = event.target;
    const url = "https://8pnogt4mud.execute-api.ap-southeast-1.amazonaws.com/default/sendContactEmail";
    const body = JSON.stringify({
      senderName: `${formFirstName.value} ${formLastName.value}`,
      senderEmail: formEmail.value,
      subject: formSubject.value,
      message: formMessage.value
    });
    const requestOptions = {
      method: "POST",
      body
    };

    fetch(url, requestOptions)
      .then((response) => {
        if(!response.ok) console.log("Error in submitting form");
      })
      .then((response) => {
        this.setState({
          showModal: true,
          modalMessage: "Thank you for reaching out. I will reply as soon as I can!",
          formFirstName: '',
          formLastName: '',
          formEmail: '',
          formSubject: '',
          formMessage: ''
        })
      })
      .catch((error) => {
        this.setState({
          showModal: true,
          modalMessage: "An unknown error occured while submitting the form. Please try again later.",
          formFirstName: '',
          formLastName: '',
          formEmail: '',
          formSubject: '',
          formMessage: ''
        })
      });
  }

  onModalCloseHandler = () => {
    const updatedState = {
      ...this.state,
      showModal: false
    }
    this.setState(updatedState);
  }

  render() {
    return (
      <Container className="pt-sm-5">
        <Row xs={1} lg={2}>
          <Col className="shadow p-5 mb-5 bg-white rounded">
            <h2 className="display-4 mb-3">Reach Out</h2>
            <p className="lead mb-4 mb-sm-5">Feel free to leave a message. I will reply as soon as I can! :)</p>
            <Form onSubmit={this.onFormSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="formFirstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" placeholder="First Name" required value={this.state.formFirstName} onChange={(event)=> this.onInputChangeHandler(event, "formFirstName")}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formLastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" placeholder="Last Name" required value={this.state.formLastName} onChange={(event)=> this.onInputChangeHandler(event, "formLastName")}/>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId="formEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="email" placeholder="Your Email" required value={this.state.formEmail} onChange={(event)=> this.onInputChangeHandler(event, "formEmail")}/>
              </Form.Group>
              <Form.Group controlId="formSubject">
                <Form.Label>Subject</Form.Label>
                <Form.Control type="text" placeholder="Subject" required value={this.state.formSubject} onChange={(event)=> this.onInputChangeHandler(event, "formSubject")}/>
              </Form.Group>
              <Form.Group controlId="formMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Message" required value={this.state.formMessage} onChange={(event)=> this.onInputChangeHandler(event, "formMessage")}/>
              </Form.Group>
              <Button variant="success" type="submit">Submit</Button>
              <Modal centered show={this.state.showModal} onHide={this.onModalCloseHandler}>
                <Modal.Header closeButton className="border-bottom-0">
                  <Modal.Title>Form submitted</Modal.Title>
                </Modal.Header>
                  <Modal.Body>{this.state.modalMessage}</Modal.Body>
                <Modal.Footer className="border-top-0">
                  <Button variant="secondary" onClick={this.onModalCloseHandler}>Close</Button>
                </Modal.Footer>
              </Modal>
            </Form>
          </Col>
          <Col className="mb-5">
            <Container>
              <h2 className="display-4 mb-3">Stay Connected</h2>
              <p className="lead">Professionally</p>
              <Button variant="link" href="https://www.linkedin.com/in/zachary-lim-wei-ling-292649ba/" target="_blank" className="align-items-center brand-button linkedin-border mb-1">
                <img src={LinkedinLogo} alt="Linkedin Logo" />
                <Col>
                  Connect through Linkedin
                </Col>
              </Button>
              <p className="lead mt-3">Or personally</p>
              <Button variant="link" href="https://www.youtube.com/channel/UCGOU7klHH5EPzKMGhbYTCfw/videos" target="_blank" rel="noreferrer" className="align-items-center brand-button youtube-border mb-1">
                <img src={YoutubeLogo} alt="YouTube Logo" />
                <Col>
                  Find me on Youtube
                </Col>
              </Button>
              <Button variant="link" href="https://www.instagram.com/zachary_limwl/" target="_blank" rel="noreferrer" className="align-items-center brand-button instagram-border mb-1">
                <img src={InstagramLogo} alt="Instagram Logo" />
                <Col>
                  Find me on Instagram
                </Col>
              </Button>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Contact;