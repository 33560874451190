import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import DesktopNavBar from '../../components/DesktopNavBar/DesktopNavBar';

import './Layout.css';

class Layout extends Component {
  render() {
    return (
      <>
        <DesktopNavBar />
        <Container fluid className="Layout">
          {this.props.children}
        </Container>
      </>
    );
  }
}

export default Layout;