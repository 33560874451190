import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';

import './DesktopNavBar.css';

const desktopNavBar = (props) => (
  <header className="DesktopNavBar">
    <Navbar fixed="top" bg="dark" variant="dark">
      <Navbar.Brand className="Brand"><Link to="/"><u>Zachary</u></Link></Navbar.Brand>
      <Nav className="ml-auto">
        <NavLink className="nav-link" exact to="/">It's Me！</NavLink>
        <NavLink className="nav-link" to="/portfolio">Passion</NavLink>
        <NavLink className="nav-link" to="/contact">Connect</NavLink>
      </Nav>
    </Navbar>
  </header>
);

export default desktopNavBar;