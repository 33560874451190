import React, { Component } from 'react';
import { Container, Row, Col, ProgressBar } from 'react-bootstrap'

import './ProgressTable.css';

class ProgressTable extends Component {
  render() {
    return (
      <Container className="progress-table pl-0">
        <Row className="mb-1">
          <Col xs={2} sm={1}></Col>
          <Col>
            <span className="d-inline-block w-25 text-right">Beginner</span>
            <span className="d-inline-block w-25 text-right">Intermediate</span>
            <span className="d-inline-block w-25 text-right">Competent</span>
            <span className="d-inline-block w-25 text-right">Expert</span>
          </Col>
        </Row>
        <Row className="mb-1 align-items-center">
          <Col xs={2} sm={1}>ReactJS</Col>
          <Col><ProgressBar animated now={75}/></Col>
        </Row>
        <Row className="mb-1 align-items-center">
          <Col xs={2} sm={1}>HTML</Col>
          <Col><ProgressBar animated variant="success" now={75}/></Col>
        </Row>
        <Row className="mb-1 align-items-center">
          <Col xs={2} sm={1}>CSS</Col>
          <Col><ProgressBar animated variant="info" now={75}/></Col>
        </Row>
        <Row className="mb-1 align-items-center">
          <Col xs={2} sm={1}>Python</Col>
          <Col><ProgressBar animated variant="warning" now={75}/></Col>
        </Row>
        <Row className="mb-1 align-items-center">
          <Col xs={2} sm={1}>Java</Col>
          <Col><ProgressBar animated variant="danger" now={75}/></Col>
        </Row>
      </Container>
    );
  }
}

export default ProgressTable;