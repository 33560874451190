import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './containers/Home/Home';
import Portfolio from './containers/Portfolio/Portfolio';
import Contact from './containers/Contact/Contact';

const routes = () => (
  <Switch>
    <Route path="/portfolio" component={Portfolio} />
    <Route path="/contact" component={Contact} />
    <Route exact path="/" component={Home} />
    <Redirect to ="/" />
  </Switch>
);

export default routes;