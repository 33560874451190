import React, { Component } from 'react';
import { Container, Row, Col, Card, Button, Carousel } from 'react-bootstrap';
import AnimePhoto1 from '../../assets/images/home/Cardcaptor-Sakura-Happiness-Memories-feature-image.jpeg';
import AnimePhoto2 from '../../assets/images/home/tensei-shitara-slime-datta-ken.png';
import MusicPhoto1 from '../../assets/images/home/music-photo-1.png';
import MusicPhoto2 from '../../assets/images/home/music-photo-2.png';
import HikingPhoto1 from '../../assets/images/home/hiking-photo-1.jpeg';
import HikingPhoto2 from '../../assets/images/home/hiking-photo-2.jpeg';
import HikingPhoto3 from '../../assets/images/home/hiking-photo-3.jpg';

import './ContentDisplay.css';

class ContentDisplay extends Component {
  render() {
    return (
      <Container className="p-0">
        <Row xs={1} sm={3}>
          <Col>
            <Card className="mb-2">
              <Card.Header>Music</Card.Header>
              <Carousel controls={false} interval={2000}>
                <Carousel.Item>
                  <img src={MusicPhoto1} alt="my lemon cover"/>
                </Carousel.Item>
                <Carousel.Item>
                  <img src={MusicPhoto2} alt="my letter cover"/>
                </Carousel.Item>
              </Carousel>
              <Card.Body>
                <Card.Title>Mostly Mandopop</Card.Title>
                <Card.Text>I love music. Although I am not good, I still love to sing and play varius musical instruments!</Card.Text>
                <Card.Text>Check out my YouTube channel for monthly (I try) uploads!</Card.Text>
                <Button variant="success" href="https://www.youtube.com/channel/UCGOU7klHH5EPzKMGhbYTCfw/videos" target="_blank">My YouTube Channel</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="mb-2">
              <Card.Header>Japan</Card.Header>
              <Carousel controls={false} interval={2000}>
                <Carousel.Item>
                  <img src={AnimePhoto1} alt="card captor sakura"/>
                </Carousel.Item>
                <Carousel.Item>
                  <img src={AnimePhoto2} alt="tensei shitara datta ken"/>
                </Carousel.Item>
              </Carousel>
              <Card.Body>
                <Card.Title>Otaku.. Not!</Card.Title>
                <Card.Text>皆さん、こんにちは！お元気ですか？(Hi everyone, how are you?)</Card.Text>
                <Card.Text>I am learning Japanese, watching Anime and reading Manga daily(almost)!</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="mb-2">
              <Card.Header>Sports</Card.Header>
              <Carousel controls={false} interval={2000}>
                <Carousel.Item>
                  <img src={HikingPhoto1} alt="hiking at horseshoe bend"/>
                </Carousel.Item>
                <Carousel.Item>
                  <img src={HikingPhoto2} alt="hiking at grand canyon"/>
                </Carousel.Item>
                <Carousel.Item>
                  <img src={HikingPhoto3} alt="hiking at japan"/>
                </Carousel.Item>
              </Carousel>
              <Card.Body>
                <Card.Title>Just to shed some fats!</Card.Title>
                <Card.Text>Badminton, Jog, Swim, Hike and Gym. <br/> I typically jog 3 times a week!</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ContentDisplay;