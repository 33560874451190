import React, { Component } from 'react';
import { Container, Button } from "react-bootstrap";
import { HashLink } from 'react-router-hash-link';
import ImageWithCredit from '../../components/ImageWithCredit/ImageWithCredit';
import ProgressTable from '../../components/ProgressTable/ProgressTable';
import { LinkContainer } from 'react-router-bootstrap';
import ContentDisplay from '../../components/ContentDisplay/ContentDisplay';

import './Home.css';
import hero from '../../assets/images/home/gary-bendig-6GMq7AGxNbE-unsplash.jpg';

class Home extends Component {
  render() {
    return (
      <div className="Home">
        <div className="Hero-Container" id="hero">
          <ImageWithCredit 
                src={hero}
                alt="hero-banner" 
                link="https://unsplash.com/photos/6GMq7AGxNbE?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink" 
                name="Gary Bendig"/>
            <div className="Hero-Overlay w-50 col-sm-6 col-md-6 col-lg-4 p-sm-5">
              <h1 className="mb-3"><strong>Hello World!</strong></h1>
              <p className="lead">This is Zachary. Welcome to my world!</p>
              <p className="lead mb-sm-5">I am currently an engineer at Defence Science & Technology Agency Singapore.</p>
              <HashLink className="btn btn-success" smooth to="/#competency">Scroll Down to know me better!</HashLink>
            </div>
        </div>
        <div className="Section-Divider" id="competency"></div>
        <Container>
          <h2 className="mb-2">Technical Competency</h2>
          <p className="lead mb-4">While my focus now is more on web development, I will be venturing into the field of Data Analytics, Machine Learning and Artificial Intelligence!</p>
          <ProgressTable />
          <LinkContainer to="/portfolio" className="mt-3">
          <Button variant="success">See More in Portfolio!</Button>
          </LinkContainer>
        </Container>
        <div className="Section-Divider" id="hobby"></div>
        <Container className="mb-5">
          <h2 className="mb-2">My Hobbies</h2>
          <p className="lead mb-4">Other than technicals, my hobbies are part of who I am!</p>
          <ContentDisplay />
        </Container>
      </div>
    );
  }
}

export default Home;