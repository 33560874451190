import React, { Component } from 'react';
import { Container, Tabs, Tab, Row, Col } from 'react-bootstrap';
import PortfolioCard from '../../components/PortfolioCard/PortfolioCard';
import StaticWebsitePhoto from '../../assets/images/portfolio/static-website-photo.png';

import './Portfolio.css';

class Portfolio extends Component {
  render() {
    return (
      <Container className="portfolio pt-3 pt-sm-5">
        <Tabs defaultActiveKey="web" transition={false} id="my-past-works" className="mb-2 mb-sm-4">
          <Tab eventKey="web" title="Web">
            <Row xs={1} sm={3}>
              <Col>
                <PortfolioCard 
                  imgSrc={StaticWebsitePhoto}
                  title="Portfolio Website"
                  text="An almost fully static website built with ReactJS"
                  techList={[
                    ["ReactJS", "To develop the user interface"],
                    ["Bootstrap","To style the user interface"],
                    ["CSS","To customise and override some of the bootstrap styles"],
                    ["AWS API Gateway","To receive the payload from the Stay Connected Form"],
                    ["AWS Lambda","To parse, process and pass data to AWS SES"],
                    ["NodeJS", "Language used for the AWS Lambda code"],
                    ["AWS SES","To send email containing data collected from Stay Connected Form"]]}/>
              </Col>
              <Col>
                <PortfolioCard 
                  title="Shopify Embedded App"
                  text="Work In progress."/>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="data" title="Data">
            Work In Progress!
          </Tab>
          <Tab eventKey="game" title="Game">
            Work In Progress!
          </Tab>
        </Tabs>
      </Container>
    );
  }
}

export default Portfolio;