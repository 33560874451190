import React, { Component } from 'react';
import { Card, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import WorkInProgressImage from '../../assets/images/work-in-progress.jpeg';

import './PortfolioCard.css';

class PortfolioCard extends Component {
  render() {
    const { title, text, techList, imgSrc } = this.props;
    const variantList = ["primary", "secondary", "success", "warning", "info", "danger", "dark"]

    const techListButtons = techList.map((techElement,index) => {
      const popover = (
        <Popover id="popover-basic">
          <Popover.Content>
            { techElement[1] }
          </Popover.Content>
        </Popover>
      );
      const techListButton = (
        <OverlayTrigger trigger="hover" placement="top" overlay={popover} key={index}>
          <Button variant={variantList[index]} className="badge badge-pill mr-2">{techElement[0]}</Button>
        </OverlayTrigger>
      )

      return techListButton;
    });
    
    return (
      <Card className="portfolio-card mb-3">
        <Card.Img variant="top" src={imgSrc} />
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>{text}</Card.Text>
        </Card.Body>
        <Card.Footer>
          { techListButtons }
        </Card.Footer>
      </Card>
    );
  }
}

PortfolioCard.defaultProps = {
  techList: [],
  imgSrc: WorkInProgressImage
}

export default PortfolioCard;